<template>
    <div class="m-2" :style="`direction:`+lang.dir">
        <b-sidebar backdrop id="pview_invoice" width="95%" aria-labelledby="sidebar-no-header-title" no-header style="direction:ltr" right :title="lang.view_invoice" shadow >
            <template #default="{ hide }">
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:150px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span>{{lang.view_invoice}}</span>
                    </div>
                    <div @click="hide" id="hideVinvo" style="text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer;width:100px;" class="btn-danger btn-sm">
                        <span>{{lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <div :style="`direction:`+lang.dir" class="m-2">
                    <v-col cols="12">
                        <b-button class="mytab1 btn btn-sm btn-default m-2" style="width:120px;background:#000;" @click="printPDF(invid)">{{lang.print_invoice}}</b-button> 
                        <b-button class="mytab1 btn btn-sm btn-default m-2" style="width:120px;background:#000;" @click="printInvo(d)">{{lang.view_pdf}}</b-button> 
                        <!-- <b-button v-if="invoice.status > 0 && (parseFloat(invoice.cancel_by) == 0 || parseFloat(invoice.cancel_by).toString() == 'NaN')" style="background:darkred;width:100px;" class="mytab2 btn btn-sm btn-danger m-2" @click="returnNow(1)" v-b-toggle.returnInvoice>ارجاع المحدد</b-button>  -->
                        <b-button v-if="invoice.status > 0 && (parseFloat(invoice.cancel_by) == 0 || parseFloat(invoice.cancel_by).toString() == 'NaN')" style="background:red;width:120px;" class="mytab2 btn btn-sm btn-danger m-2" @click="returnNow(0)" v-b-toggle.returnInvoice>{{lang.invoice_return}}</b-button> 
                        <b-button v-if="invoice.cardid == 0 && invoice.remain > 0 && (parseFloat(invoice.cancel_by) == 0 || parseFloat(invoice.cancel_by).toString() == 'NaN')" style="background:#008800;width:120px;" class="mytab2 btn btn-sm btn-success m-2" @click="invpayment()" v-b-toggle.addPayments>{{lang.make_payment}}</b-button> 
                    </v-col>
                </div>
                <div :style="`direction:`+lang.dir" class="m-2">
                    <b-table-simple :style="`direction:`+lang.dir">
                        <thead>
                            <tr>
                                <th style="border:1px solid #555;text-align:center;width:8.33%">{{lang.invoiceno}}</th>
                                <td style="border:1px solid #555;text-align:center;width:8.33%">{{ invoice.invoice_number }}</td>
                                <th style="border:1px solid #555;text-align:center;width:8.33%">{{lang.invoice_date}}</th>
                                <td style="border:1px solid #555;text-align:center;width:8.33%">{{ String(invoice.inv_date).substring(0,10) }}</td>
                                <th style="border:1px solid #555;text-align:center;width:8.33%">{{lang.invoice_tme}}</th>
                                <td style="border:1px solid #555;text-align:center;width:8.33%">{{ String(invoice.inv_date).substring(10,19) }}</td>
                            <!-- </tr>
                            <tr> -->
                                <th style="border:1px solid #555;text-align:center;width:8.33%"> {{lang.invoice_type}}</th>
                                <td style="border:1px solid #555;text-align:center;width:8.33%">{{ invoice._invtype }}</td>
                                <th style="border:1px solid #555;text-align:center;width:8.33%">{{lang.paid}}</th>
                                <td style="border:1px solid #555;text-align:center;width:8.33%">{{ invoice.paid }}</td>
                                <th style="border:1px solid #555;text-align:center;width:8.33%">{{lang.remain}}</th>
                                <td style="border:1px solid #555;text-align:center;width:8.33%">{{ invoice.remain }}</td>
                            </tr>
                        </thead>
                    </b-table-simple>
                    <b-table-simple>
                        <thead>
                            <tr v-if="invoice.cancel_by">
                                <th style="border:1px solid #555;text-align:center;width:20%">{{lang.return_date}}</th>
                                <td style="border:1px solid #555;text-align:center;width:13.33%">{{ invoice.cancel_date }}</td>
                                <th style="border:1px solid #555;text-align:center;width:20%">{{lang.return_time}}</th>
                                <td style="border:1px solid #555;text-align:center;width:13.33%">{{ invoice.cancel_date  }}</td>
                                <th style="border:1px solid #555;text-align:center;width:20%">{{lang.return_case}}</th>
                                <td style="border:1px solid #555;text-align:center;width:13.33%">{{ invoice.cancel_reson }}</td>
                            </tr>
                        </thead>
                    </b-table-simple>
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{lang.customer_name}}</th>
                                <td style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{ invoice.full_name }}</td>
                                <th style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{lang.mobile}}</th>
                                <td style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{ invoice.mobile }}</td>
                            </tr>
                            <tr v-if="invoice.company_name != null && invoice.company_name != ''">
                                <th style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{lang.company_name}}</th>
                                <td style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{ invoice.company_name }}</td>
                                <th style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{lang.company_vatid}}</th>
                                <td style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{ invoice.vatid }}</td>
                            </tr>
                            <tr v-if="$store.state.licenseType.cars || $store.state.licenseType.parts || $store.state.licenseType.carinfo">
                                <th style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{lang.plate_number}}</th>
                                <td style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{ invoice.plate_number }}</td>
                                <th style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{lang.car_model}}</th>
                                <td style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{ invoice.model }}</td>
                            </tr>
                            <tr v-if="$store.state.licenseType.cars || $store.state.licenseType.parts">
                                <th style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{lang.car_year}}</th>
                                <td style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{ invoice.manufacturing_year }}</td>
                                <th style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{lang.car_color}}</th>
                                <td style="padding:2px;height:10px;border:1px solid #555;text-align:center;">{{ invoice.color }}</td>
                            </tr>
                        </thead>
                    </b-table-simple>
                </div>
                <div :style="`direction:`+lang.dir" class="m-2">
                    <h6 class="h6title" style="font-weight:bolder;">{{lang.invoice_details}}</h6>
                    <b-table-simple>
                        <thead>
                            <tr>
                                <!-- <th v-if="returnIt" style="text-align:center;width:50px;">
                                    
                                </th> -->
                                <th style="text-align:center;">{{lang.date}}</th>
                                <th style="text-align:center;">{{lang.billoflanding}}</th>
                                <th style="text-align:center;">{{lang.trackline}}</th>
                                <th style="text-align:center;">{{lang.deliveryno}}</th>
                                <th style="text-align:center;">{{lang.trucktype}}</th>
                                <th style="text-align:center;">{{lang.laborprice}}</th>
                                <th style="text-align:center;">{{lang.total}}</th>
                                <th style="text-align:center;">{{lang.vat}} 15%</th>
                                <th style="text-align:center;">{{lang.ftotal}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in invoice.rows" :key="index">
                                <!-- <td style="text-align:center;" v-if="returnIt">
                                    <input type="checkbox" v-if="item.status > 0" :value="item.id" @click="selThis(item)">
                                </td> -->
                                <td style="text-align:center;">{{item.date}}</td>
                                <td style="text-align:center;">{{item.billoflanding}}</td>
                                <td style="text-align:center;">{{item.trackline}}</td>
                                <td style="text-align:center;">{{item.deliveryno}}</td>
                                <td style="text-align:center;">{{item.trucktype}}</td>
                                <td style="text-align:center;">{{item.laborprice}}</td>
                                <td style="text-align:center;">{{item.price}}</td>
                                <td style="text-align:center;">{{item.vat}}</td>
                                <td style="text-align:center;">{{item.ftotal}}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                    <b-table-simple style="width:400px;margin:auto;margin-inline-end:0px !important;">
                        <thead>
                            <tr>
                                <th class="text-end">{{lang.laborprice}}</th>
                                <td style="width:25%;border:1px solid #ccc;text-align:center;">{{invoice.labor}}</td>
                            </tr>
                            <tr>
                                <th class="text-end">{{lang.total_exclusive_vat}}</th>
                                <td style="width:25%;border:1px solid #ccc;text-align:center;">{{invoice.total}}</td>
                            </tr>
                            <tr>
                                <th class="text-end">{{lang.vat}} 15%</th>
                                <td style="width:25%;border:1px solid #ccc;text-align:center;">{{invoice.vat}}</td>
                            </tr>
                            <tr>
                                <th class="text-end">{{lang.total_include_vat}}</th>
                                <td style="width:25%;border:1px solid #ccc;text-align:center;">{{invoice.ftotal}}</td>
                            </tr>
                        </thead>
                    </b-table-simple>
                </div>
                <div :style="`direction:`+lang.dir" class="m-2">
                    <h6 class="h6title" style="font-weight:bolder;">{{lang.additional_method}}</h6>
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th style="text-align:center;">{{lang.adding_date}}</th>
                                <th style="text-align:center;">{{lang.description}}</th>
                                <th style="text-align:center;">{{lang.ammount}}</th>
                                <th style="text-align:center;">{{lang.method}}</th>
                                <th style="text-align:center;">{{lang.action}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in additional" :key="index">
                                <td class="text-center">{{String(item.adding_date).substr(0,10)}}</td>
                                <td class="text-center">{{item.description}}</td>
                                <td class="text-center">{{item.ammount}}</td>
                                <td class="text-center" v-html="getaddName(item)"></td>
                                <td>
                                    <v-btn class="ma-1" style="height:25px;background:red;color:#FFF;width:70px"  @click="DeleteAdd( item,index )">{{$parent.lang.delete}}</v-btn>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <th>
                                <b-form-input class="inborder"
                                    v-model="neadd.adding_date" type="date"></b-form-input>
                            </th>
                            <th>
                                <b-form-input class="inborder"
                                    v-model="neadd.description"></b-form-input>
                            </th>
                            <th>
                                <b-form-input class="inborder"
                                    v-model="neadd.ammount"></b-form-input>
                            </th>
                            <th>
                                <b-input-group>
                                    <b-form-select class="selborder"
                                        v-model="neadd.method"
                                        :options="additOptions">
                                    </b-form-select>
                                    <b-input-group-addon style="margin-right:-20px;margin-top:5px">
                                        <i class="fas fa-arrow-down"></i>
                                    </b-input-group-addon>
                                </b-input-group>
                            </th>
                            <th class="text-center" style="width:70px;">
                                <v-btn class="ma-1" style="height:25px;background:green;color:#FFF;width:70px" @click="addAddit()">{{$parent.lang.add}}</v-btn>
                            </th>
                        </tfoot>
                    </b-table-simple>
                </div>
                <div :style="`direction:`+lang.dir" class="m-2">
                    <h6 class="h6title">{{lang.payments}}</h6>
                    <b-table-simple>
                        <thead>
                            <tr>
                                <th style="text-align:center;">{{lang.payment_date}}</th>
                                <th style="text-align:center;">{{lang.payment_time}}</th>
                                <th style="text-align:center;">{{lang.payment_amount}}</th>
                                <th style="text-align:center;">{{lang.payment_cash}}</th>
                                <th style="text-align:center;">{{lang.payment_span}} / {{lang.payment_bank}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item,index) in invoice.res" :key="index">
                                <td style="text-align:center;">{{item.created_date}}</td>
                                <td style="text-align:center;">{{item.created_date}}</td>
                                <td style="text-align:center;">{{item.ftotal}}</td>
                                <td style="text-align:center;">{{item.paycash}}</td>
                                <td style="text-align:center;">{{item.payspan}}</td>
                            </tr>
                        </tbody>
                    </b-table-simple>
                </div>
                <div :style="`direction:`+lang.dir" class="m-2">
                    <invoice-expenses ref="getExpense" />
                </div>
            </template>
        </b-sidebar>
        <invPayment ref="invpayment" />
        <return-invoice ref="returnit" />
    </div>
</template>

<script>
import axios from 'axios'
import invoiceExpenses from '@/components/invoice-expenses.vue';
import invPayment from '@/components/invPayment.vue'; 
import ReturnInvoice from './return-invoice.vue';
import {SnotifyPosition} from 'vue-snotify'
export default {
    components: { invoiceExpenses,invPayment,ReturnInvoice },
    data() {
        return {
            invid: 0,
            invoice: [],
            neadd:{
                adding_date: '',
                description: '',
                ammount: '',
                method: 1,
            },
            additOptions: [
                {text: 'خصم' , value: 1},
                {text: 'اضافة' , value: 2},
            ],
            additional: [],
        }
    },
    created() {
        this.getInvoiceAdd();
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                    return this.$store.state.lang.en;
                }else{
                    return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        returntotal: function(){
            let total = 0;
            if(this.selItems.length > 0){
                for(let i=0;i<this.selItems.length;i++){
                    total = parseFloat(total) + parseFloat(this.selItems[i].ftotal);
                }
            }
            if(this.invoice.remain != 0 && this.invoice.remain != 'NaN'){
                total = this.invoice.paid;
            }
            return total;
        }
    },
    methods: {
        DeleteAdd(item,i){
            const post = new FormData();
            post.append('type','delInvoiceAdd');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',item.id);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;

                this.getInvoiceAdd();
            })
        },
        getInvoiceAdd(){
            const post = new FormData();
            post.append('type','getInvoiceAdd');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[invid]',this.invid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post

            ).then((response) => {
                const res = response.data;
                this.additional = res.results.data;
            })
        },
        getaddName(item){
            if(item.method == 1){
                return 'خصم';
            }else{
                return 'اضافة';
            }
        },
        addAddit(){
            let message = '';
            let valid = true;
            if(this.neadd.description == ''){
                message = "يرجى ادخال وصف العملية";
                valid = false;
            }
            else if(this.neadd.ammount == '' || this.neadd.ammount == 0){
                message = "يرجى ادخال وصف العملية";
                valid = false;
            }
            if(valid == false){
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append('type','addInvoiceAdd');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[invid]',this.invid);
            post.append('data[adding_date]',this.neadd.adding_date);
            post.append('data[description]',this.neadd.description);
            post.append('data[ammount]',this.neadd.ammount);
            post.append('data[method]',this.neadd.method);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post

            ).then((response) => {
                const res = response.data;
                this.getInvoiceAdd();
            })
            // this.additional.push(this.neadd);
        },
        returnNow(){
            this.$refs.returnit.invid = this.invid;
            this.$refs.returnit.return_full_money = this.invoice.paid;
            this.$refs.returnit.invoice = this.invoice;
        },
        getInvoice(){
            const post = new FormData();
            post.append('type','getTinvoicedet');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[invid]',this.invid);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                console.log(res);
                this.invoice = res.results.data;
                this.mobile = this.invoice.mobile
                this.full_name = this.invoice.full_name
                this.amount = this.invoice.remain
                this.$refs.getExpense.getexpense();
            }).then((response) => {
                this.$refs.getExpense.getexpense();
            }).then(()=>{
                this.$parent.getCurrentCards();
            });
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?tinvid='+id;
        },
        printInvo(){
            window.open("../api/print.php?tinvid="+this.invid,"_blank");
        },
        invpayment(){
            
            this.$refs.invpayment.invid = this.invid;
            this.$refs.invpayment.amount1 = this.invoice.remain;
            this.$refs.invpayment.mobile1 = this.invoice.mobile;
            this.$refs.invpayment.full_name1 = this.invoice.full_name;
        },
        hideMe(){
            document.getElementById('hideVinvo').click();
        }
    },
}
</script>