<template>
    <div>
        <b-sidebar id="returnInvoice" aria-labelledby="sidebar-no-header-title" no-header width="50rem" backdrop style="direction:ltr" right :title="lang.invoice_return" shadow >
            <template #default="{ hide }">
            <v-form
                ref="forinv" 
                lazy-validation
            >
                <div class="m-1 text-start" style="direction:rtl">
                    <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
                        <span>{{lang.invoice_return}}</span>
                    </div>
                    <div id="HideMecx" @click="hide" style="float:left;margin-left:5px;margin-top:-25px;cursor:pointer;width:100px;text-align:center;" class="btn-danger btn-sm">
                        <span>{{lang.close}}</span>
                    </div>
                </div>
                <v-divider></v-divider>
                <v-row :style="`direction:`+lang.dir+`;`" class="m-2 ps-3 ">
                    <v-row class="text-center p-3" :style="`font-size:1.2em;background:red;border:1px solid #000;color:#FFF;direction:`+$parent.$parent.lang.dir">
                        المبلغ المرتجع {{return_full_money}}
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="6">
                            <label>{{lang.payment_method}}</label>
                            <b-form-select v-model="payMethod" class="selborder" :options="paytypes"></b-form-select>
                        </v-col>
                        <v-col cols="12" md="6">
                            <label>{{lang.return_case}}</label>
                            <b-form-input v-model="returncase" class="inborder"></b-form-input>
                        </v-col>
                    </v-row>
                </v-row>
            </v-form>
            </template>
            <template #footer="" class="shadow">
                <div class="d-flex text-light align-items-center px-3 py-2">
                    <b-button type="button" @click="returnit();" variant="danger" class="ma-2" style="width:150px;">{{lang.invoice_return}}</b-button>
                </div>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    data() {
        return {
            invid: 0,
            return_full_money:0,
            invoice: {},
            returncase: '',
            payMethod:1,
            paytypes:[],
        }
    },
    computed:{
        lang: function(){
            // console.log("look",this.invoice);
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
    created() {
        this.getPyamentType();
    },
    methods: {
        getPyamentType(){
            const post = new FormData();
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                
                for(let i=0;i<res.results.data.length;i++){
                    // console.log(res);
                    this.paytypes.push({
                        text: this.lang.langname == 'ar' ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                        value: res.results.data[i].id
                    })
                }
                // this.paytypeOp = t;

                this.payMethod = res.results.data[0].id;
            })
        },
        returnit(){
            const post = new FormData();
            post.append('type','TreturnMyInvoice');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[invid]',this.invid);
            post.append('data[full_name]',this.invoice.full_name);
            post.append('data[mobile]',this.invoice.mobile);
            post.append('data[reson]',this.returncase);
            post.append('data[paytype]',this.payMethod);
            // for(let i=0;i<this.selItems.length;i++){
            //     post.append('data[items][]',this.selItems[i].id)
            // }
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                console.log(res);
                // console.log("lll",res);
                window.open("../api/print.php?tinvid="+res.results.data,"_blank");
                document.getElementById('HideMecx').click();
                this.$parent.hideMe();
            }).then(() => {
               //this.$router.push({path: "/invoicelist"})
            })
        }
    },
}
</script>