<template>
    <div class="px-3 py-2"> 
        <v-form
            ref="forinv"
            lazy-validation
        >
        <b-sidebar id="add_invoice" aria-labelledby="sidebar-no-header-title" no-header backdrop width="90rem" :style="`direction:`+$parent.lang.ldir" right :title="$parent.lang.add_invoice" shadow >
    <template #default="{ hide }">
      <div class="m-1 text-start" style="direction:rtl">
        <div style="width:120px;margin-right:10px;background:#000;color:#FFF;border-radius:5px" class="btn-primary btn-sm text-center">
          <span>{{$parent.lang.add_invoice}}</span>
        </div>
        <div @click="hide" id="HideMe" style="width:100px;text-align:center;float:left;margin-left:5px;margin-top:-25px;cursor:pointer" class="btn-danger btn-sm">
          <span>{{$parent.lang.close}}</span>
      </div>
    </div>
            <v-divider></v-divider>
        <div class="px-3 py-2" style="direction:rtl">
            <v-row v-if="$store.state.licenseType.cars || $store.state.licenseType.bill">
                <v-col cols="12" md="2" sm="12">
                    <label>{{$parent.lang.invoiceno}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.invoiceno"
                        v-model="invoice.billid" 
                        :rules="fieldRules"
                        required
                        readonly
                    >{{ invoice.billid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{$parent.lang.customerid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.customerid"
                        v-model="customer.customerid"
                        size="10"
                        @change="getName()"
                        >{{ customer.customerid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{$parent.lang.mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.mobile"
                        v-model="customer.mobile"
                        size="10"
                        id="CusMobile"
                        @change="getName()"
                        >{{ customer.mobile }}</b-form-input>
                </v-col>
                <v-col cols="12" md="4" sm="8" xs="12">
                    <label>{{$parent.lang.customer_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.customer_name"
                        v-model="customer.full_name"
                        id="CusMobile"
                        >{{ customer.full_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{$parent.lang.customer_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="customer.cctype"
                        :options="customer.ctype"
                        :label="$parent.lang.customer_type"
                        @change="changeCtype()"
                        ></b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
            </v-row>
            <v-row v-if="$store.state.licenseType.parts">
                <v-col cols="12" md="2" sm="12">
                    <label>{{$parent.lang.invoiceno}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.invoiceno"
                        v-model="invoice.billid" 
                        :rules="fieldRules"
                        required
                        readonly
                    >{{ invoice.billid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{$parent.lang.customerid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.customerid"
                        v-model="customer.customerid"
                        size="10"
                        @change="getName()"
                        >{{ customer.customerid }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{$parent.lang.mobile}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.mobile"
                        v-model="customer.mobile"
                        :rules="fieldRules"
                        size="10"
                        id="CusMobile"
                        @change="getName()"
                        >{{ customer.mobile }}</b-form-input>
                </v-col>
                <v-col cols="12" md="4" sm="8" xs="12">
                   <label>{{$parent.lang.customer_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.customer_name"
                        v-model="customer.full_name"
                        :rules="fieldRules"
                        >{{ customer.full_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{$parent.lang.model}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.model"
                        v-model="car.model"
                        :rules="fieldRules"
                        >{{ car.model }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{$parent.lang.ctype}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="customer.cctype"
                        :options="customer.ctype"
                        :label="$parent.lang.customer_type"
                        @change="changeCtype()"
                        ></b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
            </v-row>
            <v-row v-if="showcamp">
                <v-col cols="12" md="2" sm="12" xs="12" v-if="$store.state.licenseType.showproject">
                    <label>{{$parent.lang.projectname}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.projectname"
                        v-model="company.projectname"
                        >{{ company.projectname }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12">
                     <label>{{$parent.lang.company_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.company_name"
                        v-model="company.fullname"
                        >{{ company.fullname }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12" xs="12">
                    <label>{{$parent.lang.comapny_address}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.comapny_address"
                        v-model="company.address"
                        >{{ company.address }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12" xs="12">
                    <label>{{$parent.lang.company_vatid}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.company_vatid"
                        v-model="company.vatid"
                        >{{ company.fullname }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{$parent.lang.building_no}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.building_no"
                        v-model="customer.building_no"
                        >{{ company.building_no }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{$parent.lang.street_name}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.street_name"
                        v-model="company.street_name"
                        >{{ company.street_name }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{$parent.lang.branch_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.branch_number"
                        v-model="company.branch_number"
                        >{{ company.branch_number }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{$parent.lang.district}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.district"
                        v-model="company.district"
                        >{{ company.district }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="12">
                    <label>{{$parent.lang.zipcode}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.zipcode"
                        v-model="company.zipcode"
                        >{{ company.zipcode }}</b-form-input>
                </v-col>
                <v-col cols="12" md="3" sm="12">
                    <label>{{$parent.lang.city}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.city"
                        v-model="company.city"
                        >{{ company.city }}</b-form-input>
                </v-col>
            </v-row>
            <v-row  v-if="$store.state.licenseType.cars || $store.state.licenseType.carinfo">
                <v-col cols="12" md="2" sm="12">
                    <label>{{$parent.lang.plate_number}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.plate_number"
                        v-model="car.plate_number" 
                        @change="getCar()"
                    >{{ car.plate_number }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="4" xs="12">
                    <label>{{$parent.lang.car_model}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.car_model"
                        v-model="car.model"
                        >{{ car.model }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12">
                    <label>{{$parent.lang.car_year}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.car_year"
                        v-model="car.made_year"
                        >{{ car.made_year }}</b-form-input>
                </v-col>
                <v-col cols="12" md="2" sm="8" xs="12">
                    <label>{{$parent.lang.car_color}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.car_color"
                        v-model="car.color"
                        >{{ car.color }}</b-form-input>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{$parent.lang.invoice_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.invtype"
                        :options="invoice.invtypes"
                        :label="$parent.lang.invoice_type"
                        @change="InvType()"
                        ></b-form-select>
                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{$parent.lang.vat_type}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.vtype"
                        :options="invoice.vattype"
                        :label="$parent.lang.vat_type"
                        @change="changePtype()"
                        ></b-form-select>
                        <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="2" sm="6" xs="12">
                    <label>{{$parent.lang.payment_method}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.paytype"
                        :options="invoice.paytypes"
                        :label="$parent.lang.payment_method"
                        @change="changePtype()"
                        ></b-form-select>
                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="3" sm="6" xs="12">
                    <label>{{$parent.lang.saler_name}}</label>
                    <b-input-group>
                    <b-form-select
                        class="selborder"
                        v-model="invoice.userid"
                        :options="invoice.users"
                        :label="$parent.lang.saler_name"
                        @change="changePtype()"
                        ></b-form-select>
                    <b-input-group-append style="margin-right:-20px;margin-top:5px;">
                        <i class="fas fa-arrow-down"></i>
                        </b-input-group-append>
                    </b-input-group>
                </v-col>
                <v-col cols="12" md="3" sm="6" xs="12" v-if="$store.state.caneditinvoicedate">
                    <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="invoice.date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <label>{{$parent.lang.invoice_date}}</label>
                        <b-form-input
                            id="input-live"
                            v-model="invoice.date"
                            aria-describedby="input-live-help input-live-feedback"
                            prepend-icon="mdi-calendar"
                            :placeholder="$parent.lang.invoice_date"
                            readonly
                            trim
                            v-bind="attrs"
                            v-on="on"
                            class="input-sm inborder"
                        ></b-form-input>
                        
                        </template>
                        <v-date-picker
                        v-model="date"
                        scrollable
                        >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="modal = false"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                        >
                            OK
                        </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col cols="12" md="3" sm="6" xs="12" v-else>
                    <label>{{$parent.lang.invoice_date}}</label>
                    <b-form-input
                        class="inborder"
                            v-model="invoice.date"
                            :label="$parent.lang.invoice_date"
                            prepend-icon="mdi-calendar"
                            readonly
                        >{{ invoice.date }}</b-form-input>
                </v-col>
                <v-col cols="12" md="6" sm="8" xs="12">
                    <label>{{$parent.lang.notes}}</label>
                    <b-form-textarea
                        class="inborder"
                        :label="$parent.lang.invoice_notes"
                        v-model="invoice.notes"
                        >{{ invoice.notes }}</b-form-textarea>
                </v-col>
            </v-row>
            <v-row>
                <v-window>{{$parent.lang.invoice_details}}</v-window>
                <v-simple-table striped hover style="width:100%;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-center">{{$parent.lang.date}}</th>
                                <th class="text-center">{{$parent.lang.billoflanding}}</th>
                                <th class="text-center">{{$parent.lang.trackline}}</th>
                                <th class="text-center">{{$parent.lang.deliveryno}}</th>
                                <th class="text-center">{{$parent.lang.trucktype}}</th>
                                <th class="text-center">{{$parent.lang.laborprice}}</th>
                                <th class="text-center">{{$parent.lang.price}}</th>
                                <th class="text-center">{{$parent.lang.vat}}</th>
                                <th class="text-center">{{$parent.lang.ftotal}}</th>
                                <th class="text-center"></th>
                            </tr>
                        </thead>
                        <tbody id="tablerow">
                            <tr v-for="(item,index) in tbrows" :key="index">
                                <td>{{ item.date }}</td>
                                <td>{{ item.billoflanding }}</td>
                                <td>{{ item.trackline }}</td>
                                <td>{{ item.deliveryno }}</td>
                                <td>{{ item.trucktype }}</td>
                                <td>{{ item.laborprice }}</td>
                                <td>{{ item.price }}</td>
                                <td>{{ item.vat }}</td>
                                <td>{{ item.ftotal }}</td>
                                <td>
                                    <b-button type="button" variant="danger" style="width:65px" @click="removeItem(index)">حذف</b-button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b-form-input class="inborder"
                                     type="date"
                                     v-model="newrow.date"></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.billoflanding"
                                        type="number"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.trackline"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.deliveryno"
                                        type="number"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.trucktype"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.laborprice"
                                        type="number"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.price"
                                        @change="CalcVat()"
                                        type="number"
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.vat"
                                        type="number"
                                        readonly
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-form-input
                                        class="inborder"
                                        v-model="newrow.ftotal"
                                        type="number"
                                        readonly
                                    ></b-form-input>
                                </td>
                                <td>
                                    <b-button type="button" class="btn-sm" variant="success" @click="addNewRow()">{{$parent.lang.add}}</b-button>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr style="display:none;">
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"> % {{$parent.lang.discount}}</th>
                                <th class="text-center">
                                    <b-form-input
                                        class="inborder"
                                        v-model="invoice.discount"
                                        @change="calcAll()"
                                        type="number"
                                    ></b-form-input>
                                </th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                            </tr>
                            <tr>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center">{{$parent.lang.total}}</th>
                                <th class="text-center">{{ full_labor_total }}</th>
                                <th class="text-center">{{ full_price }}</th>
                                <th class="text-center">{{ full_vat }}</th>
                                <th class="text-center">{{ full_ftotal }}</th>
                                <th class="text-center"></th>
                            </tr>
                            <!-- <tr>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"> تأمين</th>
                                <th class="text-center">
                                    <b-form-input
                                        class="inborder"
                                        v-model="invoice.insurance"
                                        @change="calcAll()"
                                        type="number"
                                    ></b-form-input>
                                </th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                            </tr>
                            <tr>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center">الاجمالي بعد التامين</th>
                                <th class="text-center">{{ full_total_in }}</th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                                <th class="text-center"></th>
                            </tr> -->
                        </tfoot>
                        
                    </template>
                </v-simple-table>
            </v-row>
            <v-row>
                <v-col cols="12" md="4" sm="6" xs="12">
                    <label v-if="invoice.paytype == 1 || invoice.paytype == 3">{{$parent.lang.cash_payment}}</label>
                    <b-form-input
                        class="inborder"
                        :label="$parent.lang.cash_payment"
                        v-if="invoice.paytype == 1 || invoice.paytype == 3"
                        v-model="invoice.payment.cash"
                        type="number"
                        >{{ invoice.payment.cash }}</b-form-input>
                </v-col>
                <v-col cols="12" md="4" sm="6" xs="12">
                    <label v-if="invoice.paytype == 2 || invoice.paytype == 3">{{$parent.lang.span_payment  }}</label>
                    <b-form-input
                        class="inborder"
                        v-if="invoice.paytype == 2 || invoice.paytype == 3"
                        :label="$parent.lang.span_payment"
                        v-model="invoice.payment.span"
                        type="number"
                        >{{ invoice.payment.span }}</b-form-input>
                </v-col>
            </v-row>
        </div>
        </template>
        <template #footer="" class="shadow">
        <div class="d-flex text-light align-items-center px-3 py-2">
          <b-button type="button" @click="addInvoice()" id="addBTN" variant="success" class="ma-2 btn-sm" style="width:130px;">{{$parent.lang.create_invoice}}</b-button>
        </div>
      
      </template>
        </b-sidebar>
        </v-form>
        
      </div>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default{
    props:[
        'custom','prods','cardid'
    ],
    data() {
        return {
            payby: 1,
            itemsworkdes:[
                
            ],
            car: {
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
            },
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            modal: false,
            showcamp: false,
            company:{
                fullname: '',
                vatid: '',
                projectname: '',
                building_no: '',
                street_name: '',
                branch_number: '',
                district: '',
                zipcode: '',
                city:'',
                address:'',
            },
            customer: {
                id:0,
                mobile: '',
                full_name: '',
                customerid: '',
                cctype: 1,
                ctype: [
                    {
                        text: this.$parent.lang.individual_client,
                        value: 1,
                    },
                    {
                        text: this.$parent.lang.company_client,
                        value: 2,
                    }
                ]
            },
            invoice:{
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                payment:{
                    cash: 0,
                    span: 0
                },
                billid: 1,
                discount:0 ,
                t_discount:0 ,
                vtype:2,
                vattype: [
                    {
                        text: this.$parent.lang.prices_include_vat,
                        value: 3,
                    },
                    {
                        text: this.$parent.lang.prices_exclusive_vat,
                        value: 2,
                    },
                    {
                        text: this.$parent.lang.prices_without_vat,
                        value: 1,
                    },
                ],
                invtype:1,
                invtypes: [
                    {
                        text: this.$parent.lang.none_paid_invoice,
                        value: 2,
                    },
                    {
                        text: this.$parent.lang.paid_invoice,
                        value: 1,
                    },
                ],
                paytype: 2,
                paytypes: [
                    
                ],
                userid: 0,
                users: [

                ],
                notes: '',
                insurance: 0,
            },
            fieldRules: [
                v => !!v || this.$parent.lang.required_field,
            ],
            tbrows:[
            ],
            newrow:{
                date: '',
                billoflanding: '',
                trackline: '',
                deliveryno: '',
                trucktype: '',
                laborprice: '',
                price: '',
                vat: '',
                ftotal: '',
            },
        }
    },
    created() {
        this.getInvSettings();
        // setTimeout(() => {
        //     this.getInfo(); 
        // }, 2000);
        this.getPyamentType();
    },
    computed:{
        full_total_in: function(){
            let t = 0;
            t = parseFloat(this.full_ftotal) - parseFloat(this.invoice.insurance);

            return this.$RoundNum(t);
        },
        full_labor_total: function(){
            let t = 0;
            const tbrows = this.tbrows;
            for(let i = 0;i<this.tbrows.length;i++){
                t = parseFloat(t) + parseFloat(this.tbrows[i].laborprice);
            }
            return this.$RoundNum(t);
        },
        full_price: function(){
            let t = 0;
            const tbrows = this.tbrows;
            for(let i = 0;i<this.tbrows.length;i++){
                t = parseFloat(t) + parseFloat(this.tbrows[i].price);
            }
            return this.$RoundNum(t);
        },
        full_vat: function(){
            let t = 0;
            const tbrows = this.tbrows;
            for(let i = 0;i<this.tbrows.length;i++){
                t = parseFloat(t) + parseFloat(this.tbrows[i].vat);
            }
            return this.$RoundNum(t);
        },
        full_ftotal: function(){
            let t = 0;
            const tbrows = this.tbrows;
            for(let i = 0;i<this.tbrows.length;i++){
                t = parseFloat(t) + parseFloat(this.tbrows[i].ftotal);
            }
            return this.$RoundNum(t);
        },
    },
    methods: {
        getProducts() {
            if(this.newrow.itemcode == ''){
                return false;
            }
            const post = new FormData();
            post.append("type","getProducts");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[item_number]',this.newrow.itemcode);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                
                if(response.data.results.data.length > 0){
                    if(response.data.results.data[0].qty > 0){
                        this.newrow.itemcode = response.data.results.data[0].itemcode;
                        this.newrow.description = response.data.results.data[0].name;
                        this.newrow.price = response.data.results.data[0].price;
                        this.newrow.qty = 1;
                        this.calcKit();
                        document.getElementById('newitemqty').focus();
                    }else{
                        this.newrow.itemcode = '';
                        this.newrow.description = '';
                        this.newrow.qty = '';
                        this.newrow.price = '';
                        this.newrow.total = '';
                        let message = "الصنف المختار غير متوفر حاليا... يرجى اضافة فاتورة الشراء قبل اضافة المخزون ليتم احتساب ضريبة القيمة المضافة بشكل صحيح";
                        this.$snotify.error(message, 'تنبيه', {
                            timeout: 1000000,
                            showProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            position: SnotifyPosition.centerCenter,
                            buttons: [
                                {
                                    text: this.$parent.lang.close, 
                                    action: (toast) => {
                                        this.$snotify.remove(toast.id); 
                                    } 
                                },
                            ]
                        });
                    }
                    
                }else{
                    this.newrow.itemcode = '';
                    this.newrow.description = '';
                    this.newrow.qty = '';
                    this.newrow.price = '';
                    this.newrow.total = '';
                    let message = "الصنف المختار غير متوفر حاليا... يرجى اضافة فاتورة الشراء قبل اضافة المخزون ليتم احتساب ضريبة القيمة المضافة بشكل صحيح";
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.$parent.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                }
                this.calcKit();
            })
        },
        getItemname(){
            if(this.newrow.itemcode == ''){
                return false;
            }
            const post = new FormData();
            post.append("type" , "getItemBycode"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[itemcode]',this.newrow.itemcode)
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                const res = response.data.results.data.results;
                if(res.length != 0){
                    this.newrow.item_name = res[0].name;
                    this.newrow.item_price = res[0].price;
                }
                this.calcKit();
                document.getElementById('newitemqty').focus();
            })
            // .then(() => {
            //     // newrow = {
            //     //     itemcode: '',
            //     //     item_name: '',
            //     //     qty: 1,
            //     //     item_price: 0,
            //     //     total: 0,
            //     //     vat: 0,
            //     //     ftotal: 0
            //     // }
            // })
        },
        getInfo(){
            
            if(this.custom == null) {return false;}
            this.customer.mobile = this.custom.mobile;
            this.company.fullname = this.custom.company;
            this.company.vatid = this.custom.vatid;
            this.getName();
        },
        resetInvoice(){
            this.payby = 1;
            this.itemsworkdes = [
                
            ];
            this.car = {
                plate_number: '',
                color: '',
                model: '',
                made_year: '',
                workdes: '',
            };
            this.modal = false;
            this.showcamp =  false;
            this.company = {
                fullname: '',
                vatid: '',
                address: '',
            };
            this.customer.id = 0;
            this.customer.mobile = '';
            this.customer.full_name = '';
            this.customer.customerid = '';
            this.customer.cctype = '';
            this.invoice.payment.cash = 0;
            this.invoice.payment.span = 0;
            this.invoice.payment.discount = 0;
            this.invoice.payment.vtype = 0;
            this.invoice.payment.t_discount = 0;
            
            this.tbrows = [
            ];
            this.newrow = {
                date: '',
                billoflanding: '',
                trackline: '',
                deliveryno: '',
                trucktype: '',
                laborprice: '',
                price: '',
                vat: '',
                ftotal: '',
            }
        },
        addInvoice(){
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            if(!this.$refs.forinv.validate()){
                return false;
            }
            if(this.customer.mobile == '') {
                this.customer.mobile = 1234567890;
            }
            if(this.customer.full_name == '') {
                this.customer.full_name = this.$parent.lang.individual_client;
            }
            // console.log("asdfasf",this.tbrows.length);
            if(this.tbrows.length == 0){
                let message = this.$parent.lang.you_can_not_add_empty_invoice;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
           if(this.customer.cctype == 2 && this.company.fullname == ''){
               let message = this.$parent.lang.company_ifnormation_required;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
           }
            document.getElementById('addBTN').disabled = true;
            const post = new FormData();
            post.append("type" , "addTInvoice"); 
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            for(const [key, value] of Object.entries(this.customer)){
                post.append('data[customer]['+key+']]',value);
            }
            for(const [key, value] of Object.entries(this.company)){
                post.append('data[company]['+key+']]',value);
            }
            for(let i = 0; i < this.tbrows.length;i++){
                for(const [key, value] of Object.entries(this.tbrows[i])){
                    post.append('data[tbrows]['+i+']['+key+']]',value);
                }
            }
            for(const [key, value] of Object.entries(this.car)){
                post.append('data[car]['+key+']]',value);
            }
            post.append("data[inovice][date]",this.invoice.date);
            post.append("data[inovice][itemcode]",0);
            post.append("data[inovice][payment][cash]",this.invoice.payment.cash);
            post.append("data[inovice][payment][span]",this.invoice.payment.span);
            post.append("data[inovice][billid]",this.invoice.billid);
            post.append("data[inovice][vtype]",this.invoice.vtype);
            post.append("data[inovice][invtype]",this.invoice.invtype);
            post.append("data[inovice][paytype]",this.invoice.paytype);
            post.append("data[inovice][userid]",this.invoice.userid);
            post.append("data[inovice][full_total]",this.full_price);
            post.append("data[inovice][full_labor]",this.full_labor_total);
            post.append("data[inovice][full_vat]",this.full_vat);
            post.append("data[inovice][full_ftotal]",this.full_ftotal);
            post.append("data[inovice][t_discount]",this.invoice.t_discount);
            post.append("data[inovice][invtype]",this.invoice.invtype);
            post.append("data[inovice][cash]",this.invoice.payment.cash);
            post.append("data[inovice][span]",this.invoice.payment.span);
            post.append("data[inovice][notes]",this.invoice.notes);
            post.append("data[inovice][insurance]",this.invoice.insurance);
            post.append("data[inovice][projectname]",this.company.projectname);
            post.append("data[cardid]",this.cardid);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then((resposne) => {
                console.log("addinvoice",resposne.data);
                document.getElementById('addBTN').disabled = false;
                if (this.$snotify.notifications.length > 0) {
                    this.$snotify.notifications.forEach(notification => {
                        this.$snotify.remove(notification.id)
                    });
                }
                let message = this.$parent.lang.invoice_added;
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                this.printPDF(resposne.data.results.data.results.newID);
                document.getElementById('HideMe').click();
                this.resetInvoice();
                
                this.$parent.getCurrentCards();
                
            })

        },
        printInvo(id){
            window.open('../api/print.php?tinvid='+id,'_blank');
        },
        printPDF (id)
        {
            let pdfFrame = document.body.appendChild(document.createElement('iframe'));
            pdfFrame.style.display = 'none';
            pdfFrame.onload = () => (void pdfFrame.contentWindow.print());
            pdfFrame.src = '../api/print.php?tinvid='+id;
        },
        changePtype(){
            let tbs = [];
             if(this.tbrows.length != 0){
                for(let i = 0; i < this.tbrows.length; i++){
                    let price = parseFloat(this.tbrows[i].price);
                    const vs = this.$calcVat(price,this.invoice.vtype);
                    const itm = {
                        date: this.tbrows[i].date,
                        billoflanding: this.tbrows[i].billoflanding,
                        trackline: this.tbrows[i].trackline,
                        deliveryno: this.tbrows[i].deliveryno,
                        trucktype: this.tbrows[i].trucktype,
                        laborprice: this.tbrows[i].laborprice,
                        price: this.tbrows[i].price,
                        vat: vs.vat,
                        ftotal: vs.ftot,
                    }
                    tbs.push(itm);
                }
            }
            this.tbrows = tbs;
            this.CalcVat();
        },
        CalcVat(){
            if(this.newrow.laborprice == ''){
                this.newrow.laborprice = 0;
            }
            if(this.invoice.vtype == 1){
                this.newrow.vat = 0;
                this.newrow.ftotal = parseFloat(this.newrow.price) + parseFloat(this.newrow.laborprice);
            }
            else if(this.invoice.vtype == 2){
                this.newrow.vat = parseFloat(parseFloat(this.newrow.price) + parseFloat(this.newrow.laborprice)) * parseFloat(0.15);
                this.newrow.ftotal = parseFloat(parseFloat(this.newrow.price) + parseFloat(this.newrow.laborprice)) + parseFloat(this.newrow.vat);
            }
            else if(this.invoice.vtype == 3){
                const vat = this.$calcVat(parseFloat(this.newrow.price) + parseFloat(this.newrow.laborprice),3);
                this.newrow.vat = vat.vat;
                this.newrow.ftotal = vat.ftot;
            }
            this.newrow.vat = this.$RoundNum(this.newrow.vat);
            this.newrow.ftotal = this.$RoundNum(this.newrow.ftotal);
        },
        calcAll(){
            this.full_total = 0;
            this.full_vat = 0;
            this.full_ftotal = 0;
            if(this.tbrows.length != 0){
                for(let i = 0; i < this.tbrows.length; i++){
                    this.full_total = parseFloat(this.full_total) + parseFloat(this.tbrows[i].total);
                    this.full_vat = parseFloat(this.full_vat) + parseFloat(this.tbrows[i].vat);
                    this.full_ftotal = parseFloat(this.full_ftotal) + parseFloat(this.tbrows[i].ftotal);
                }
            }
            if(this.invoice.discount > 30){
                this.invoice.discount = 30;
            }
            if(this.invoice.discount != 0){
                this.invoice.t_discount = parseFloat(this.invoice.discount) * parseFloat(this.full_total) / parseFloat(100);
                this.full_total = parseFloat(this.full_total) - parseFloat(this.invoice.discount) * parseFloat(this.full_total) / parseFloat(100);
                this.full_vat = parseFloat(this.full_vat) - parseFloat(this.invoice.discount) * parseFloat(this.full_vat) / parseFloat(100);
                this.full_ftotal = parseFloat(this.full_ftotal) - parseFloat(this.invoice.discount) * parseFloat(this.full_ftotal) / parseFloat(100);
            }
            this.full_total = this.$RoundNum(this.full_total);
            this.full_vat = this.$RoundNum(this.full_vat);
            this.full_ftotal = this.$RoundNum(this.full_ftotal);
            if(this.invoice.paytype == 1 || this.invoice.paytype == 3){
                this.invoice.payment.cash = this.full_ftotal;
                this.invoice.payment.span = 0;
            }else if(this.invoice.paytype == 2){
                this.invoice.payment.cash = 0;
                this.invoice.payment.span = this.full_ftotal;
            }
            if(this.invoice.invtype == 2){
                this.invoice.payment.cash = 0;
                this.invoice.payment.span = 0;
            }
        },
        InvType () {
            if(this.invoice.invtype == 1){
                if(this.paytype == 1){
                    this.invoice.payment.cash = this.full_ftotal;
                    this.payment.span = 0;
                }else{
                    this.invoice.payment.span = this.full_ftotal;
                    this.invoice.payment.cash = 0;
                }
            }else{
                this.invoice.payment.span = 0;
                this.invoice.payment.cash = 0;
            }
        },
        removeItem(item){
            this.tbrows.splice(item, 1);
            this.calcAll()
        },
        calcKit() {
            const vt = this.invoice.vtype;
            const price = +this.newrow.item_price * +this.newrow.qty;
            const vat = this.$calcVat(price,vt);
            this.newrow.total = this.$RoundNum(vat.tot);
            this.newrow.ftotal = this.$RoundNum(vat.ftot);
            this.newrow.vat = this.$RoundNum(vat.vat);
        },
        addNewRow() {
            if (this.$snotify.notifications.length > 0) {
                this.$snotify.notifications.forEach(notification => {
                    this.$snotify.remove(notification.id)
                });
            }
            let message = '';
            let shownoti = false;

            if(this.newrow.date == ''){
                message = 'يرجى ادخال التاريخ';
                shownoti = true;
            }
            if(this.newrow.billoflanding == '' && shownoti == false){
                message = 'يرجى ادخال رقم البلويصة';
                shownoti = true;
            }
            if(this.newrow.trackline == '' && shownoti == false){
                message = 'يرجى ادخال خط السير';
                shownoti = true;
            }
            if(this.newrow.trucktype == '' && shownoti == false){
                message = 'يرجى ادخال نوع الشاحنة';
                shownoti = true;
            }
            if(this.newrow.price == '' && shownoti == false){
                message = 'يرجى ادخال السعر';
                shownoti = true;
            }
            if(shownoti){
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.$parent.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.newrow.laborprice == ''){
                this.newrow.laborprice = 0;
            }
            this.tbrows.push(this.newrow);
            this.newrow = {
                date: '',
                billoflanding: '',
                trackline: '',
                deliveryno: '',
                trucktype: '',
                laborprice: '',
                price: '',
                vat: '',
                ftotal: '',
            }
            this.calcAll()
        },
        changeCtype() {
            if(this.customer.cctype == 2){
                this.showcamp = true;
            }else{
                this.showcamp = false;
            }
        },
        getInvSettings() {
            const post = new FormData();
            post.append('type','getInvSettings');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[all]','all');
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res != '' && res.error.number == 200){
                        if(res.results.data.users.length != 0){
                            for(let i= 0; i < res.results.data.users.length; i++){
                                this.invoice.users.push({
                                    text: res.results.data.users[i].full_name,
                                    value: res.results.data.users[i].id
                                })
                            }
                        }
                        if(res.results.data.desk.length != 0){
                            this.itemsworkdes = [];
                            this.allProducts = res.results.data;
                            for(let i= 0; i < res.results.data.desk.length; i++){
                                this.itemsworkdes.push(res.results.data.desk[i].title);
                            }
                        }
                        if(res.results.data.products.length != 0){
                            this.allProducts = res.results.data;
                            for(let i= 0; i < res.results.data.products.length; i++){
                                this.itemsworkdes.push(res.results.data.products[i].itemcode + ' - ' + res.results.data.products[i].name);
                            }
                        }
                    }
                }
            )
        },
        getName(){
            const post = new FormData();
            post.append('type','customerInfo');
            post.append('auth',this.$cookies.get(this.$COOKIEPhase));
            post.append('data[mobile]',this.customer.mobile);
            post.append('data[customerid]',this.customer.customerid);
            axios.post(
                this.$SAMCOTEC.r_path, post
            ).then(
                (response) => {
                    const res = response.data;
                    if(res.error.number == 200){
                        this.customer.id = res.results.data['id'];
                        this.customer.mobile = res.results.data['mobile'];
                        this.customer.full_name = res.results.data['full_name'];
                        this.customer.customerid = res.results.data['customerid'];
                        this.company.fullname = res.results.data['company_name'];
                        this.company.vatid = res.results.data['company_vatid'];
                        this.company.branch_number = res.results.data['branch_number'];
                        this.company.building_no = res.results.data['branch_number'];
                        this.company.city = res.results.data['city'];
                        this.company.district = res.results.data['district'];
                        this.company.street_name = res.results.data['street_name'];
                        this.company.zipcode = res.results.data['zipcode'];
                    }
                }
            )
        },
        getPyamentType(){
            const post = new FormData();
            post.append('type','getPayTypes');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                this.payTypeOp = [];
                for(let i=0;i<res.results.data.length;i++){
                    this.invoice.paytypes.push({
                        text: this.$parent.lang.langname == 'ar' ? res.results.data[i].type_name : res.results.data[i].type_name_en,
                        value: res.results.data[i].id
                    })
                }
                // this.paytypeOp = t;

                this.invoice.paytype = res.results.data[0].id;
            })
        },
        getCar(){
            const post = new FormData();
            post.append('type','carInfo');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[plate_number]',this.car.plate_number);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then(
                (response) => {
                const res = response.data;
                if(res.error.number == 200){
                    this.car = res.results.data;
                }
                }
            )
        }
    },
}
</script>